<template>
  <multiselect v-bind="$attrs" v-on="$listeners" class="select-check"><slot></slot></multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  name: "Multiselector",
  components: { Multiselect },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.multiselect {
  color: black;
  z-index: 20;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--highlight,
.multiselect__option--highlight::after,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #3369fb;
  color: white;
}

.multiselect__option--selected {
  background: white;
}

.select-check .multiselect__option--selected::after {
  content: "\2713";
  color: #3369fb;
  font-size: 20px;
}

.select-check
  .multiselect__option--selected.multiselect__option--highlight::after {
  content: "\2713";
  color: white;
  background: #3369fb;
}

.select-check > .multiselect__tags {
  min-height: 44px;
  padding-top: 10px;
}

.select-check > .multiselect__select {
  height: 44px;
}

.select-check .multiselect__single {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
