<template>
  <div class="">
    <div class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12">{{ spreadsheet.original_file_name }}</div>
    <div class="">
      <label class="text-xl text-black mb-1 inline-block">Enter a Javascript function to run on each row</label>
      <code-mirror-editor
        :value="local.transform_function"
        @input="update"
        :options="editoroptions"
      >
      </code-mirror-editor>
    </div>
    <div class="mt-5">
      <span class="text-center w-full inline-block text-xl mb-1">Input file</span>
      <div class="w-full max-h-96 overflow-auto" id="preview"></div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import XLSX from "xlsx";
export default {
  name: "ApplyJavascript",
  props: ["value"],
  mounted() {
    this.$emit("input", this.local);
    var reader = new FileReader();
    reader.onload = function(e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      workbook.SheetNames.forEach(function(sheetName) {
        // Here is your object
        var XL_HTML = XLSX.utils.sheet_to_html(workbook.Sheets[sheetName]);
        document.getElementById("preview").innerHTML = XL_HTML;
      });
    };

    reader.onerror = function(ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(this.$store.getters.selectedFile);
  },
  components: {
    "code-mirror-editor": codemirror,
  },
  data() {
    return {
      editoroptions: {
        mode: "javascript",
        theme: "solarized dark",
        lineNumbers: true,
        lineWrapping: true,
        styleActiveSelected: true,
        line: true,
        extraKeys: { "Ctrl-Space": "autocomplete" },
      },
    };
  },
  computed: {
    local() {
      return this.value && Object.keys(this.value).length > 0
        ? this.value
        : { transform_function: "function(row){ return row; }" };
    },
    job() {
      return this.$store.state.job;
    },
    spreadsheet() {
      return this.$store.state.spreadsheets[this.job.spreadsheets[0]];
    },
  },
  methods: {
    update(value) {
      this.$emit("input", { ...this.local, ["transform_function"]: value });
    },
  },
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.52.2/theme/solarized.min.css");
#preview tr:first-child {
  position: sticky;
  top: -1px;
  background-color: white;
  box-shadow: inset 0 1px 0 grey, inset 0 -1px 0 grey;
}
</style>
