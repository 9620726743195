<template>
  <div>
    <div v-if="job" class="min-h-steps pt-20">
      <!-- <h1 class="is-centered">Configure {{ jobType.title }}</h1> -->
      <DropDuplicates
        v-if="jobType.slug == 'drop_duplicates'"
        v-model="job.configuration"
      />
      <SplitSheets
          v-else-if="jobType.slug == 'split_large_sheets'"
          v-model="job.configuration"
      />
      <ApplyJavascript
        v-else-if="jobType.slug == 'apply_javascript'"
        v-model="job.configuration"
      />
      <Merge
        v-else-if="jobType.slug == 'merge'"
        v-model="job.configuration"
      />
      <CompareRows
        v-else-if="jobType.slug == 'compare_rows'"
        v-model="job.configuration"
      />
      <CompareColumns
        v-else-if="jobType.slug == 'compare_columns'"
        v-model="job.configuration"
      />
      <p v-else>Unable to find configuration</p>
    </div>
  </div>
</template>

<script>
import DropDuplicates from "@/components/configure/DropDuplicates";
import ApplyJavascript from "@/components/configure/ApplyJavascript";
import CompareColumns from "@/components/configure/CompareColumns";
import CompareRows from "@/components/configure/CompareRows";
import SplitSheets from "@/components/configure/SplitSheets";
import Merge from "@/components/configure/Merge";
import { coreApi } from "../api/index";
import XLSX from "xlsx";
import Axios from "axios";
export default {
  name: "ConfigureJob",
  components: {
    DropDuplicates,
    ApplyJavascript,
    SplitSheets,
    Merge,
    CompareColumns,
    CompareRows,
  },
  data: () => {
    return {
      output: null,
      loading: false,
    };
  },
  computed: {
    jobType() {
      if (this.job == null) {
        return;
      }
      return this.$store.state.jobTypes.find(
        (jt) => jt.slug === this.job.job_type
      );
    },
    job() {
      return this.$store.state.job;
    },
  },
  methods: {
    run() {
      this.$store.commit("setLoading", true);
      this.$store.commit("setIsDisabled", true);
      coreApi.runJob(this.job.uuid, this.job.configuration).then(
        (response) => {
          if (this.jobType.slug == "apply_javascript" && this.output == null) {
            this.$store.commit("setIsDisabled", false);
            this.$store.commit("setLoading", false);
            this.output = response.outputs[0]["uuid"];
            this.$store.commit("setIsOutput", true);
            Axios.get(
              `/api/v1/core/spreadsheet/${
                this.output
              }/download/?preview=${true}`
            )
              .then((resp) => {
                var workbook = XLSX.read(resp.data, {
                  type: "buffer",
                });
                workbook.SheetNames.forEach(function(sheetName) {
                  var XL_HTML = XLSX.utils.sheet_to_html(
                    workbook.Sheets[sheetName]
                  );
                  document.getElementById("output_preview").innerHTML = XL_HTML;
                });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setTimeout(() => {
              this.$emit("input", response);
              this.$store.commit("setIsDisabled", false);
              this.$store.commit("setLoading", false);
              this.$store.commit("setIsOutput", false);
              this.output = null;
            }, 3000);
          }
        },
        (error) => {
          this.loading = false;
          document.getElementById("output_preview").innerHTML =
            "javascript Parsing Error Detected";
          console.log(error);
          return false;
        }
      );
    },
  },
};
</script>

<style></style>
