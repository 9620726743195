<template>
  <header class="p-2 md:p-5">
    <div class="bg-blue-300 bg-defaultBlue rounded-lg md:rounded-xl font-sans pb-8 flex items-stretch flex-col min-h-header">
      <div
        class="flex items-center justify-between px-4 md:px-10 py-2 md:py-6 max-w-screen-2xl md:mx-auto w-full"
      >
        <router-link to="/">
          <img src="../assets/logo.svg" alt="logo" />
        </router-link>
        <div class="md:hidden cursor-pointer" @click="toggleMobile">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 2H18V3.8H0V2ZM0 8.4H18V10.2H0V8.4ZM0 14.8H18V16.6H0V14.8Z"
              fill="black"
            />
          </svg>
        </div>
        <div
          class="bg-white h-screen md:hidden fixed z-10 w-full m-0 top-0 left-0 px-6 py-4"
          v-if="mobileShow"
          @click="toggleMobile"
        >
          <div class="flex justify-between items-center">
            <router-link class="button is-light" to="/">
              <img src="../assets/logo.svg" alt="logo" />
            </router-link>
            <div class="md:hidden cursor-pointer" @click="toggleMobile">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3164 6.80371L6.31641 18.8037M6.31641 6.80371L18.3164 18.8037"
                  stroke="#101828"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="items-stretch pt-12 flex flex-col">
            <router-link
              to="/about"
              class="text-lg font-extrabold py-3 font-garnett text-primary"
            >
              About
            </router-link>
            <router-link
              to="/policy"
              class="text-lg font-extrabold py-3 font-garnett text-primary"
            >
              Privacy
            </router-link>
            <div class="border-t border-grey-300" />
            <a
              href="https://support.ablestar.com/"
              target="_blank"
              class="text-lg font-extrabold py-3 font-garnett text-primary"
            >
              Help Center
            </a>
          </div>
        </div>
        <div class="items-center gap-x-10 hidden md:flex lg:ml-auto">
          <router-link to="/about" class="text-base text-black hover:underline flex items-center group font-garnett">
            About
          </router-link>
          <router-link to="/policy" class="text-base text-black hover:underline flex items-center group font-garnett">
            Privacy
          </router-link>
          <a
            href="https://support.ablestar.com/"
            target="_blank"
            class="text-base text-black hover:underline flex items-center group font-garnett"
          >
            Help Center
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5"
            >
              <path
                d="M4.25 12.5178L9.75 2.99154"
                stroke="black"
                stroke-width="1.25"
              />
              <path
                d="M11.2148 8.45557L9.75074 2.99146L4.28664 4.45557"
                stroke="black"
                stroke-width="1.25"
              />
            </svg>
          </a>
        </div>
      </div>
      <div
        class="px-3 md:px-24 md:flex md:items-center max-w-screen-2xl md:mx-auto md:gap-16 lg:gap32 my-auto w-full"
      >
        <div
          class="flex flex-col text-primary flex-1"
        >
          <h1 class="text-center md:text-left font-extrabold text-4xl md:text-5xl lg:text-header font-garnett my-8 md:my-0">
            {{ headLine }}
          </h1>
          <p class="text-base lg:leading-8 lg:mr-32 mt-7 text-center md:text-left">
            {{ description }}
          </p>
        </div>
        <div class="hidden md:block" :class="imageName !== 'hero_image.png' ? 'lg:mr-20' : ''">
          <img :src="require(`../assets/${imageName}`)" :class="imageName !== 'hero_image.png' ? 'w-48 h-48' : ''" />
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "NavHeader",
  props: {
    headLine: {
      type: String,
      default: 'Easily manage your spreadsheets',
    },
    description: [String],
    imageName: {
      type: String,
      default: 'hero_image.png',
    }
  },
  data() {
    return {
      mobileShow: false,
    };
  },
  methods: {
    toggleMobile() {
      this.mobileShow = !this.mobileShow;
    }
  }
}
</script>

<style scoped>

.button {
  background: transparent !important;
}

.button:hover, .button:focus {
  background: transparent !important;
}

.navigation {
  max-width: 1400px;
  margin: 0 auto;
}

</style>