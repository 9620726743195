<template>
  <div>
    <nav-header />
    <div class="px-5 md:px-20 mt-8 mb-16">
      <div
        class="max-w-7xl mx-auto"
      >
        <h1 class="text-black text-2xl font-garnett font-bold">About</h1>
        <p class="mt-8 text-black text-xl max-w-3xl">The Spreadsheet Toolkit is a collection of utilities designed to help ecommerce merchants. We know how much time it can take to compare, merge and manage spreadsheets and hope these tools can help.</p>
      </div>
    </div>
    <contact-us />
  </div>
</template>
<script>
import NavHeader from "../components/NavHeader.vue";
import ContactUs from "../components/ContactUs.vue";
export default {
  name: "about",
  components: {NavHeader, ContactUs},
};
</script>
