<template>
  <div>
    <div class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12">{{ spreadsheet.original_file_name }}</div>
    <div class="flex flex-col">
      <label class="text-xl text-black mb-1 inline-block">Enter maximum rows per file</label>
      <input type="number" :value="local.keep" @input="update('keep', $event.target.value)"
        class="flex-1 focus:ring-transparent block w-full rounded-lg text-xl focus:text-grey-900 hover:text-grey-800 py-2 px-3 outline-none text-grey-900 placeholder:text-grey-500 focus:border-lightBlue2 border-grey-300 focus:shadow-sm border"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "SplitSheets",
  props: ["value"],
  mounted() {
    this.$emit("input", this.local);
  },
  computed: {
    local() {
      return this.value && Object.keys(this.value).length > 0
          ? this.value
          : {keep: 1000, how: "column", column: 1, isDisabled: false};
    },
    spreadsheet() {
      return this.$store.state.spreadsheets[this.job.spreadsheets[0]];
    },
    job() {
      return this.$store.state.job;
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", {...this.local, [key]: value});
    },
  },
};
</script>

<style>
</style>