<template>
  <div>
    <div class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12">{{ spreadsheet.original_file_name }}</div>
    <div class="flex flex-col md:flex-row gap-6">
      <div class="flex-1 min-w-0">
        <label class="text-xl text-black mb-1 inline-block">Which rows do you want to remove?</label>
        <MultiSelector
          :options="removeList.map(i => i.value)"
          :custom-label="d => removeList.find(i => i.value === d).label"
          :value="local.keep"
          @input="val => update('keep', val)"
          :searchable="false"
        />
      </div>
      <div class="flex-1 min-w-0">
        <label class="text-xl text-black mb-1 inline-block">Single or multiple column?</label>
        <MultiSelector
          :options="howList.map(i => i.value)"
          :custom-label="d => howList.find(i => i.value === d).label"
          :value="local.how"
          @input="val => update('how', val)"
          :searchable="false"
        />
      </div>
      <div class="flex-1 min-w-0" v-if="local.how === 'column'">
        <label class="text-xl text-black mb-1 inline-block">Which column should we use?</label>
        <MultiSelector
          :options="spreadsheet.columns"
          :value="local.column"
          @input="(val) => update('column', val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelector from "../MultiSelector.vue";
export default {
  name: "DropDuplicates",
  props: ["value"],
  components: { MultiSelector },
  mounted() {
    this.$emit("input", this.local);
  },
  computed: {
    local() {
      return this.value && Object.keys(this.value).length > 0
        ? this.value
        : { keep: "first", how: "column", column: null };
    },
    spreadsheet() {
      return this.$store.state.spreadsheets[this.job.spreadsheets[0]];
    },
    job() {
      return this.$store.state.job;
    },
    removeList() {
      return [
        {
          label: 'Keep the first duplicated row',
          value: 'first',
        },
        {
          label: 'Keep the last duplicated row',
          value: 'last',
        },
        {
          label: 'Remove any row that is duplicated',
          value: 'false',
        }
      ]
    },
    howList() {
      return [
        {
          label: 'All columns in the row are duplicated',
          value: 'all'
        },
        {
          label: 'A single column is duplicated',
          value: 'column',
        },
      ]
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.local, [key]: value });
    },
  },
};
</script>

<style>
</style>