import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localforage from "localforage";
Vue.use(Vuex);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const local_store = localforage.createInstance({
    name: 'spreadsheet-store',
})

const vuexPersist = new VuexPersistence({
    storage: local_store,
    reducer: (state) => {
        return JSON.parse(JSON.stringify(state))
    },
    asyncStorage: true,
})


export const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    jobTypes: [],
    spreadsheets: {},
    job: null,
    csrfToken: null,
    selectedJobTypeSlug: null,
    selectedFiles: null,
    isDisabled: true,
    isOutput: false,
    loading: false,
  },
  getters: {
    jobType: (state) => {
      if (state.selectedJobTypeSlug == null) {
        return null;
      }

      return state.jobTypes.find((jt) => jt.slug == state.selectedJobTypeSlug);
    },
    selectedFile: (state) => {
      if (state.selectedFiles == null) {
        return null;
      }
      return state.selectedFiles;
    },
    isDisabled: (state) => {
      return state.isDisabled;
    },
    isOutput: (state) => {
      return state.isOutput;
    },
    loading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    setJobTypes(state, payload) {
      state.jobTypes = payload;
    },
    setJobTypeSlug(state, jobTypeSlug) {
      state.selectedJobTypeSlug = jobTypeSlug;
    },
    setJob(state, { job }) {
      state.job = job;
    },
    setSpreadsheet(state, { spreadsheet }) {
      state.spreadsheets[spreadsheet.uuid] = spreadsheet;
    },
    setCsrfToken(state) {
      state.csrfToken = readCookie("csrftoken");
    },
    setSelectedFile(state, file) {
      state.selectedFiles = file;
    },
    setIsDisabled(state, value) {
      state.isDisabled = value;
    },
    setIsOutput(state, value) {
      state.isOutput = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
  },
});
