<template>
  <div>
    <nav-header />
    <div class="px-5 md:px-20 mt-8 mb-16">
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 max-w-7xl mx-auto"
      >
        <div
          v-for="jobType in jobTypes"
          :key="jobType.slug"
          class="bg-bg2 rounded-lg"
          @click="setJobType(jobType.slug)"
        >
          <div class="p-5 flex flex-col h-full group cursor-pointer rounded-lg hover:shadow-md">
            <div class="w-20 h-20 mb-3.5">
              <img
                class="w-full h-auto"
                :src="require(`.././assets/${jobType.slug}_light.svg`)"
                :alt="`${jobType.title} Image`"
              />
            </div>
            <div class="mb-5">
              <h1 class="font-garnett text-lg font-bold text-black mb-3">
                {{ jobType.title }}
              </h1>
              <p class="text-black text-base">
                {{ jobType.description }}
              </p>
            </div>
            <div class="mt-auto">
              <button class="flex items-center gap-2 text-lightBlue2 group-hover:gap-4 transition-all">
                Run toolkit
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="#3369FB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us :theme="'home'" />
  </div>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
import ContactUs from "../components/ContactUs.vue";
export default {
  name: "ChooseJob",
  components: { NavHeader, ContactUs },
  data() {
    return {
      selectedJobTypeSlug: null,
      file_0: null,
      file_1: null,
      spreadsheet_0: null,
      spreadsheet_1: null,
    };
  },
  computed: {
    jobTypes() {
      return this.$store.state.jobTypes;
    },
  },
  methods: {
    setJobType(jobTypeSlug) {
      this.$store.commit("setJobTypeSlug", jobTypeSlug);
      this.$router.push({ path: `/steps/${jobTypeSlug}` }).catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(err);
        }
      });
    },
  },
};
</script>
