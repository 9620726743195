import ChooseJob from '@/pages/ChooseJob';
import Steps from '@/pages/Steps';
import About from '@/pages/About';
import Help from '@/pages/Help';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import Vue from 'vue';
import Router from 'vue-router';
import {waitForStorageToBeReady} from "@/store/utils";

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'ChooseJob',
            component: ChooseJob,
            meta: {title: "Choose Job"}
        },
        {
            path: '/steps/:jobTypeSlug/',
            name: 'Steps',
            component: Steps,
            meta: {title: "Steps"}
        },
        {
            path: '/about',
            name: 'About',
            component: About,
            meta: {title: "About"}
        },
        {
            path: '/policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy,
            meta: {title: "Privacy Policy"}
        },
        {
            path: '/help',
            name: 'Help',
            component: Help,
            meta: {title: "Help"}
        },
    ]
})

router.beforeEach(async (to, from, next) => {
    await waitForStorageToBeReady();
    next();
});

export default router;
