<template>
  <div class="flex flex-col md:flex-row gap-6">
    <div class="flex-1 min-w-0">
      <div class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12">{{ spreadsheet_0.original_file_name }}</div>
      <label class="text-xl text-black mb-1 inline-block">Which column should we use in the first spreadsheet?</label>
      <MultiSelector
        :options="spreadsheet_0.columns"
        :value="local.left"
        @input="(val) => update('left', val)"
      />
    </div>
    <div class="flex-1 min-w-0">
      <div class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12">{{ spreadsheet_1.original_file_name }}</div>
      <label class="text-xl text-black mb-1 inline-block">Which column should we use in the second spreadsheet?</label>
      <MultiSelector
        :options="spreadsheet_1.columns"
        :value="local.right"
        @input="val => update('right', val)"
      />
    </div>
  </div>
</template>

<script>
import MultiSelector from "../MultiSelector.vue";
export default {
  name: "Merge",
  props: ["value"],
  components: { MultiSelector },
  mounted() {
    this.$emit("input", this.local);
  },
  computed: {
    local() {
      return this.value && Object.keys(this.value).length > 0
        ? this.value
        : { left: null, right: null };
    },
    spreadsheet_0() {
      return this.$store.state.spreadsheets[this.job.spreadsheets[0]];
    },
    spreadsheet_1() {
      return this.$store.state.spreadsheets[this.job.spreadsheets[1]];
    },
    job() {
      return this.$store.state.job;
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.local, [key]: value });
    },
  },
};
</script>
<style>
.options {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
</style>
