import axios from "axios";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.headers = { "Access-Control-Request-Headers": "*" };

class CoreApi {
  constructor(url) {
    console.log(url);
    this.baseUrl = '/api/v1/core';
  }
  async getJobTypes() {
    const response = await axios.get(`${this.baseUrl}/jobtypes/`);
    return response.data;
  }
  async postSpreadsheet(formData) {
    const response = await axios.post(`${this.baseUrl}/spreadsheet/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  async postJob(data) {
    const response = await axios.post(`${this.baseUrl}/job/`, data);
    return response.data;
  }
  async runJob(uuid, configuration) {
    const response = await axios.post(`${this.baseUrl}/job/${uuid}/run/`, {
      configuration: configuration,
    });
    return response.data;
  }
}

export default CoreApi;
