import App from './App'
// import Buefy from 'buefy'
// import 'buefy/dist/buefy.css'
import Vue from 'vue'
import router from './routes'
import { store } from './store/store.js'
import {coreApi} from './api/index'
import './assets/css/style.css'

// Vue.use(Buefy)
Vue.config.productionTip = false
// const VueUploadComponent = require('vue-upload-component')
// Vue.component('file-upload', VueUploadComponent)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>'
})
const JobTypes = [];
let callee = async()=>{
  let response = await coreApi.getJobTypes()
  JobTypes.push(...response)
}
callee()
store.commit('setJobTypes',JobTypes)
store.commit('setCsrfToken')
