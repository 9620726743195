<template>
  <div>
    <div v-if="job">
      <h1 class="text-center text-xl text-black mb-5">
        {{ jobType.title }} Results
      </h1>
      <div class="flex flex-col gap-6">
        <div
          class="flex-1 flex flex-col"
          v-for="output in job.outputs"
          :key="output.uuid"
        >
          <div
            class="bg-fileName border-lightBlue2 border rounded text-black py-2 px-3 text-xl truncate mb-12"
          >
            {{ output.original_file_name }} ({{ output.row_count }} rows)
          </div>

          <div class="w-full max-h-96 overflow-auto" :id="output.uuid"></div>

          <div class="self-center inline-block mt-12">
            <a
              class="p-3 bg-lightBlue2 text-white rounded-lg"
              :href="`/api/v1/core/spreadsheet/${
                output.uuid
              }/download/?preview=${false}`"
              ><button class="btn-without-float">Download resuls</button></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import XLSX from "xlsx";

export default {
  name: "JobResults",
  data() {
    return {
      preview: true,
    };
  },
  mounted() {
    if (this.job) {
      let output = null;
      for (output in this.job.outputs) {
        let uuid = this.job.outputs[output]["uuid"];
        Axios.get(`/api/v1/core/spreadsheet/${uuid}/download/`, {
          params: {
            preview: true,
          },
        })
          .then((response) => {
            this.sheet_data = response;
            var workbook = XLSX.read(this.sheet_data.data, {
              type: "buffer",
            });
            workbook.SheetNames.forEach(function (sheetName) {
              var XL_HTML = XLSX.utils.sheet_to_html(
                workbook.Sheets[sheetName]
              );
              document.getElementById(uuid).innerHTML = XL_HTML;
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  },
  computed: {
    jobType() {
      if (this.job == null) {
        return;
      }
      return this.$store.state.jobTypes.find(
        (jt) => jt.slug === this.job.job_type
      );
    },
    job() {
      return this.$store.state.job;
    },
  },
  methods: {},
};
</script>
