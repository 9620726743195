<template>
  <nav aria-label="Progress">
    <ol role="list" class="flex items-center justify-center">
      <li
        v-for="(step, stepIdx) in steps"
        :key="step.name"
        :class="[
          stepIdx !== steps.length - 1 ? 'pr-16 sm:pr-36' : '',
          'relative',
        ]"
      >
        <template v-if="stepIdx < value">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-lightBlue2" />
          </div>
          <a
            href="#"
            @click.prevent="onStepClick(stepIdx)"
            class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-lightBlue2 bg-lightBlue2 hover:bg-indigo-900"
          >
            <span
              class="bg-transparent text-white group-hover:text-grey-50 font-semibold"
              aria-hidden="true"
            >{{ stepIdx + 1 }}</span>
            <span class="absolute top-10 text-grey-900 whitespace-nowrap">{{ step.name }}</span>
          </a>
        </template>
        <template
          v-else-if="stepIdx === value"
          condition="step.status === 'current'"
        >
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href="#"
            @click.prevent="onStepClick(stepIdx)"
            class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-lightBlue2 bg-white"
            aria-current="step"
          >
            <span
              class="h-2.5 w-2.5 rounded-full bg-lightBlue2"
              aria-hidden="true"
            />
            <span class="absolute top-10 text-grey-900 whitespace-nowrap">{{ step.name }}</span>
          </a>
        </template>
        <template v-else>
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div>
          <a
            href="#"
            @click.prevent="onStepClick(stepIdx)"
            class="group relative cursor-not-allowed flex h-8 w-8 items-center justify-center rounded-full border-2 border-grey-300 bg-white hover:border-grey-400"
          >
            <span
              class="bg-transparent text-grey-400 group-hover:text-grey-500 font-semibold"
              aria-hidden="true"
            >{{ stepIdx + 1 }}</span>
            <span class="absolute top-10 text-grey-400 whitespace-nowrap">{{ step.name }}</span>
          </a>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Steps",
  props: ['value', 'steps'],
  data() {
    return {
    };
  },
  methods: {
    onStepClick(index) {
      if (this.value > index)
        this.$emit('input', index)
    }
  },
};
</script>
