<template>
  <div>
    <nav-header
      :head-line="jobType.title"
      :description="jobType.description"
      :image-name="`${jobType.slug}_light.svg`"
    />
    <div class="px-5 md:px-20 mt-8 mb-24">
      <div class="max-w-7xl mx-auto">
        <steps-component :steps="steps" v-model="currentStep" class="mb-20" />

        <UploadFile ref="uploadFile" @input="setJob" v-if="currentStep === 0" />
        <ConfigureJob
          ref="configureJob"
          @input="setJobWithOutputs"
          v-else-if="currentStep === 1"
        />
        <JobResults v-else-if="currentStep === 2" />
      </div>
      <div class="max-w-7xl mx-auto mt-16 text-grey-500 text-base flex justify-end md:flex-row flex-col">
        <div class="mt-5 md:mt-0 flex items-center gap-2.5 justify-end">
          <button
            class="inline-flex gap-2 w-44 justify-center items-center py-2.5 rounded-lg border border-grey-500 text-grey-500"
            v-if="currentStep < 1 ? false : true"
            :loading="loading"
            @click="callPrev"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 18L9 12L15 6" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Back
          </button>
          <button
            class="inline-flex gap-2 w-44 justify-center items-center py-2.5 rounded-lg"
            :class="(job && job.job_type === 'split_large_sheets' ? false : isDisabled) ? 'bg-disabledBg border-disabledBorder border cursor-not-allowed text-disabledText' : 'bg-lightBlue2 text-white'"
            v-if="currentStep > 1 ? false : true"
            :disabled="
              (job && job.job_type === 'split_large_sheets' ? false : isDisabled) || loading
            "
            @click="callNext"
          >
            {{ buttonText }}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 18L15 12L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
      </div>
    </div>
    <contact-us />
  </div>
</template>

<script>
import UploadFile from "@/components/UploadFile";
import ConfigureJob from "@/components/ConfigureJob";
import JobResults from "@/components/JobResults";
import NavHeader from "../components/NavHeader.vue";
import ContactUs from "../components/ContactUs.vue";
import StepsComponent from "../components/Steps.vue";

// import Api from "@/services/api";
export default {
  name: "Steps",
  components: {
    UploadFile,
    ConfigureJob,
    JobResults,
    NavHeader,
    ContactUs,
    StepsComponent,
  },
  data() {
    return {
      currentStep: 0,
      selectedJobTypeSlug: null,
      canConfigure: null,
      steps: [
        { name: "Upload Files", href: "#", status: "complete" },
        { name: "Configure", href: "#", status: "complete" },
        { name: "Results", href: "#", status: "current" },
      ],
    };
  },
  mounted() {
    this.$store.commit("setLoading", false);
  },
  computed: {
    isDisabled() {
      return (
        this.$store.state.isDisabled ||
        (this.currentStep > 0
          ? Object.keys(this.job.configuration).length &&
            Object.values(this.job.configuration).some(
              (x) => x === null || x === ""
            )
          : false)
      );
    },
    isOutput() {
      return this.$store.state.isOutput;
    },
    loading() {
      return this.$store.state.loading;
    },
    maxStep() {
      if (this.jobType == null) {
        return 0;
      }

      if (this.job == null) {
        return 1;
      }

      if (this.job.outputs.length == 0) {
        return 2;
      }
      return 3;
    },
    jobTypes() {
      return this.$store.state.jobTypes;
    },
    job() {
      return this.$store.state.job;
    },
    jobType() {
      return this.$store.getters.jobType;
    },
    buttonText() {
      if (this.currentStep >= 1 && !this.isOutput) {
        return "Run";
      } else {
        return "Next";
      }
    },
  },
  methods: {
    setJobType(jobTypeSlug) {
      this.$store.commit("setJobTypeSlug", jobTypeSlug);
      this.$nextTick(() => {
        this.currentStep = 1;
      });
    },
    callNext() {
      if (this.currentStep == 0) {
        this.$refs.uploadFile.configure();
      }
      if (this.currentStep == 1) {
        this.$refs.configureJob.run();
      }
    },
    callPrev() {
      if (this.currentStep == 2) {
        this.currentStep = 1;
      }
      else if (this.currentStep == 1) {
        this.currentStep = 0;
      }
    },
    setJob(job) {
      this.$store.commit("setJob", { job: job });

      this.$nextTick(() => {
        this.currentStep = 1;
      });
    },
    setJobWithOutputs(job) {
      this.$store.commit("setJob", { job: job });
      this.$nextTick(() => {
        this.currentStep = 2;
      });
    },
  },
};
</script>

<style></style>
