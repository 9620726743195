<template>
  <div class="bg-white font-inter">
    <router-view :key="$route.fullPath" />
    <footer
      class="px-4 py-8 md:py-14 md:px-16 xl:px-28 flex flex-col gap-y-8 text-black max-w-screen-2xl md:mx-auto md:flex-row-reverse justify-between"
    >
      <div class="flex items-center gap-x-4 md:gap-x-5">
        <a
          href="https://www.ablestar.com/terms-and-conditions/"
          target="_blank"
          class="text-sm underline opacity-80"
        >
          Terms & Conditions
        </a>
        <div class="flex items-center gap-x-2.5">
          <div class="w-2 h-2 bg-success rounded-full"></div>
          <a
            href="https://status.ablestar.com"
            target="_blank"
            class="text-sm opacity-80"
            >Service Status</a
          >
        </div>
      </div>
      <p class="text-sm opacity-80">
        © {{ currentYear }}. Ablestar. All Rights Reserved.
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      currentRoute: "ChooseJob",
    };
  },
  mounted() {
    this.setPageProperty();
  },
  methods: {
    setPageProperty() {
      this.currentRoute = this.$router.currentRoute.name;
    },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  watch: {
    $route() {
      this.setPageProperty();
    },
  },
};
</script>
