<template>
  <div>
    <!--UPLOAD-->
    <!-- <form enctype="multipart/form-data" novalidate> -->
    <!-- <h1>To continue, upload {{ jobType.spreadsheet_count }} file(s)</h1> -->
    <div
      class="bg-grey-50 border border-lightBlue border-dashed h-upload cursor-pointer relative hover:bg-grey-100 flex flex-col items-center p-4 md:p-10 rounded-xl"
    >
      <input
        type="file"
        ref="fileupload"
        :multiple="jobType.spreadsheet_count === 2"
        @change="(e) => chooseFile(e)"
        accept=".csv, .xlsx, .xls"
        class="absolute top-0 left-0 right-0 bottom-0 opacity-0 w-full cursor-pointer"
      />
      <img src="../assets/upload.svg" alt="file-upload" class="mt-1" />
      <p class="text-xl mt-10 text-black">
        Drag & drop files or <span class="text-lightBlue2">Browse</span>
      </p>
      <p class="text-base text-grey-500 mt-2.5 text-center">
        Supported formats: CSV or Excel files
      </p>
      <p class="text-grey-400 text-sm">Showing first 100 rows of file</p>

      <button
        class="text-xl mt-10 text-lightBlue2 relative z-10"
        @click.stop="loadSampleData"
      >
        Load Sample Data
      </button>
      <div
        v-if="jobType.spreadsheet_count === 1 && file_0"
        class="absolute bottom-0 left-0 right-0 p-5"
      >
        <div
          class="border border-lightBlue2 bg-fileName rounded w-full py-2 px-3 text-xl text-black inline-flex items-center justify-between"
        >
          <span class="truncate">{{ file_0.name }}</span>
          <button @click.prevent="clearInput(0)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#101828"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        v-else-if="jobType.spreadsheet_count === 2"
        class="absolute bottom-0 left-0 right-0 p-5 flex flex-col md:flex-row gap-5"
      >
        <div
          class="flex-1 min-w-0 border border-lightBlue2 bg-fileName rounded w-full py-2 px-3 text-xl text-black inline-flex items-center justify-between"
          v-if="file_0"
          >
          <span class="truncate">{{ file_0.name }}</span>
          <button @click.prevent="clearInput(0)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#101828"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div v-else class="flex-1"></div>
        <div
          class="flex-1 min-w-0 border border-lightBlue2 bg-fileName rounded w-full py-2 px-3 text-xl text-black inline-flex items-center justify-between"
          v-if="file_1"
          >
          <span class="truncate">{{ file_1.name }}</span>
          <button @click.prevent="clearInput(1)">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#101828"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div v-else class="flex-1"></div>
      </div>
    </div>

    <!-- </form> -->
    <!-- <div class=" upload-file-field">
          <h1>To continue, upload {{ jobType.spreadsheet_count }} file(s)</h1>
          <div class="columns is-multiline is-centered">
            <div :class="select_class()">
              <b-field class="file is-primary is-centered" :class="{'data-loaded': dataLoaded}">
                <b-upload
                  class="file-label"
                  v-model="file_0"
                  @input="uploadFile(0)"
                >
                  <a class="file-cta">
                    <span class="file-label">Upload 1 file (s)</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="file_0">{{ file_0.name }}</span>
              </b-field>
              <div class="file is-centered">
                <span
                  class="load-sample"
                  v-show="showLeftSample"
                  @click="loadSample(0)"
                  >Load Sample Data</span
                >
              </div>
              <div id="preview_1" :class="select_preview()"></div>
            </div>
            <div class="column is-half" v-if="jobType.spreadsheet_count > 1">
              <b-field class="file is-primary is-centered" :class="{'data-loaded': dataLoaded}">
                <b-upload
                  class="file-label"
                  v-model="file_1"
                  @input="uploadFile(1)"
                >
                  <a class="file-cta">
                    <span class="file-label">Upload 1 file (s)</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="file_1">{{ file_1.name }}</span>
              </b-field>
              <div class="file is-centered">
                <span
                  v-show="showRightSample"
                  class="load-sample"
                  @click="loadSample(1)"
                  >Load Sample Data</span
                >
              </div>
              <div id="preview_2" :class="select_preview()"></div>
            </div>
          </div>
        </div> -->
  </div>
</template>

<script>
import { coreApi } from "../api/index";
import XLSX from "xlsx";
import Axios from "axios";
export default {
  name: "UploadFile",
  data() {
    return {
      file_0: null,
      file_1: null,
      spreadsheet_0: null,
      spreadsheet_1: null,
      showLeftSample: true,
      showRightSample: true,
      dataLoaded: false,
    };
  },
  beforeMount() {
    if (this.jobType == null) {
      this.$store.commit(
        "setJobTypeSlug",
        this.$router.currentRoute.path.split("/")[2]
      );
    }
    this.$store.commit("setIsDisabled", true);
  },
  computed: {
    jobType() {
      return this.$store.getters.jobType;
    },
  },
  methods: {
    canConfigure() {
      if (this.jobType == null) {
        this.$store.commit("setIsDisabled", true);
        return;
      }

      if (this.spreadsheet_0 == null) {
        this.$store.commit("setIsDisabled", true);
        return;
      }

      if (this.jobType.spreadsheet_count == 2 && this.spreadsheet_1 == null) {
        this.$store.commit("setIsDisabled", true);
        return;
      }

      this.$store.commit("setIsDisabled", false);
    },
    select_class() {
      if (
        this.jobType.slug == "drop_duplicates" ||
        this.jobType.slug == "apply_javascript" ||
        this.jobType.slug == "split_large_sheets"
      ) {
        return "column is-full center-flex";
      } else {
        return "column is-half center-flex";
      }
    },
    select_preview() {
      if (
        this.jobType.slug == "drop_duplicates" ||
        this.jobType.slug == "apply_javascript"
      ) {
        return "preview_1_1";
      } else {
        return "preview_2_1";
      }
    },
    ExcelToHTML(index) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_HTML = XLSX.utils.sheet_to_html(workbook.Sheets[sheetName]);
          if (index == 1) {
            document.getElementById("preview_2").innerHTML = XL_HTML;
          } else {
            document.getElementById("preview_1").innerHTML = XL_HTML;
          }
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };
      this.dataLoaded = true;
      reader.readAsBinaryString(this[`file_${index}`]);
    },
    uploadFile(index) {
      if (index === 0) {
        this.showLeftSample = !this.showLeftSample;
      } else {
        this.showRightSample = !this.showRightSample;
      }

      let formData = new FormData();
      this.ExcelToHTML(index);
      if (this.jobType.slug == "apply_javascript") {
        this.$store.commit("setSelectedFile", this[`file_${index}`]);
      }
      formData.append("file", this[`file_${index}`]);
      coreApi.postSpreadsheet(formData).then((response) => {
        this[`spreadsheet_${index}`] = response;
        this.$store.commit("setSpreadsheet", { spreadsheet: response });
        this.canConfigure();
      });
    },
    chooseFile(e) {
      if (e.target.files.length === 0) return;
      
      if (this.jobType.spreadsheet_count === 1) {
        this.file_0 = e.target.files[0];
        this.uploadFile(0);
        return;
      }

      if (this.jobType.spreadsheet_count === 2 && this.file_0 === null) {
        this.file_0 = e.target.files[0];
        this.uploadFile(0);

        if (e.target.files.length > 1 && this.file_1 === null) {
          this.file_1 = e.target.files[1];
          this.uploadFile(1);
        }
        return;
      }
      
      if (this.jobType.spreadsheet_count === 2 && this.file_1 === null) {
        this.file_1 = e.target.files[0];
        this.uploadFile(1);
      }
    },
    clearInput(index) {
      this[`file_${index}`] = null;
      this.$store.commit("setIsDisabled", true);
    },
    configure() {
      const data = {
        job_type: this.jobType.slug,
        spreadsheets: [this.spreadsheet_0.uuid],
      };

      if (this.spreadsheet_1 != null) {
        data.spreadsheets.push(this.spreadsheet_1.uuid);
      }

      coreApi.postJob(data).then(
        (job) => {
          // preserve the original order (left / right)
          const preservedSpreadsheets = [];
          data.spreadsheets.forEach((sheet) => {
            if (job.spreadsheets.includes(sheet)) {
              preservedSpreadsheets.push(sheet);
            }
          });
          this.$emit("input", {
            ...job,
            spreadsheets: preservedSpreadsheets,
          });
        },
        (error) => {
          console.error(error);
          return false;
        }
      );
    },
    loadSampleData() {
      if (this.jobType.spreadsheet_count === 1) {
        this.loadSample(0);
      }
      if (this.jobType.spreadsheet_count === 2) {
        if (this.file_0 === null) this.loadSample(0);
        if (this.file_1 === null) this.loadSample(1);
      }
    },
    loadSample(index) {
      Axios.get(`/api/v1/core/spreadsheet/uuid/load_sample`, {
        params: { file_name: this.jobType.samples[index] },
      })
        .then((response) => {
          let file = new File([response.data], this.jobType.samples[index], {
            type: "application/csv",
          });
          this[`file_${index}`] = file;
          this.uploadFile(index);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.field.has-addons {
  justify-content: center;
}
.preview_1_1 {
  max-height: 347px;
  overflow: scroll;
  max-width: 990px;
  align-self: center;
}
.preview_2_1 {
  max-height: 347px;
  overflow: auto;
  max-width: 600px;
}
table {
  overflow-y: scroll;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
td {
  border: 1px solid #ddd;
  padding: 8px;
}
table tr:first-child td {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: transparent;
  color: #000000;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
table td {
  text-align: center;
}
td:not([align]) {
  text-align: center;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.load-sample {
  margin-top: 10px;
  text-decoration: none;
  color: #132d5f;
  font-size: 13px;
}
.load-sample:hover {
  cursor: pointer;
}
.field.file.is-primary.data-loaded {
  margin: 0;
  background: #ffff;
  border-top: 1px solid #c6d3ca;
  border-left: 1px solid #c6d3ca;
  border-right: 1px solid #c6d3ca;
  border-radius: 4px;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between !important;
  padding: 10px;
}
.file-name {
  border: none;
  padding: 5px 0;
}
.upload.control.file-label {
  max-width: 150px !important;
}
</style>
