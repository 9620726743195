<template>
  <div>
    <nav-header />
    <div class="px-5 md:px-20 mt-8 mb-16">
      <div class="max-w-7xl mx-auto">
        <h1 class="text-black text-2xl font-garnett font-bold">
          Privacy Policy
        </h1>
        <div class="mt-8 text-xl text-black max-w-3xl">
          <div class="space-y-7">
            <p class="flex items-start gap-4" v-for="item in privacy" :key="item">
              <img
                svg-inline
                svg-sprite
                src="../assets/ablestar_star.svg"
                alt="iconfinder  arrow right image"
              />
              {{ item }}
            </p>
          </div>
          <p class="pt-16">
            The Spreadsheet Toolkit is run by Ablestar and our full privacy
            policy is viewable on
            <a
              href="https://www.ablestar.co/privacy-policy/"
              class="text-lightBlue2 underline hover:text-blue"
              target="_blank"
              >Ablestar website</a
            >
          </p>
        </div>
      </div>
    </div>
    <contact-us />
  </div>
</template>
<script>
import NavHeader from "../components/NavHeader.vue";
import ContactUs from "../components/ContactUs.vue";
import { privacy } from "../utils/const";

export default {
  name: "PrivacyPolicy",
  components: { NavHeader, ContactUs },
  data() {
    return {
      privacy: privacy,
    };
  },
};
</script>
