<template>
  <div class="bg-defaultBlue h-contactUs flex items-center justify-center p-4">
    <div
      v-if="theme === 'home'"
      class="flex items-center justify-center flex-col"
    >
      <h1
        class="text-center md:text-left font-extrabold text-3xl md:text-4xl font-garnett text-primary mb-10"
      >
        Don’t see what You are looking for?
      </h1>
      <a
        class="bg-white py-3.5 px-16 rounded-sm block text-base font-medium text-black hover:shadow-lg"
        href="mailto:support@ablestar.co"
        >Contact Us</a
      >
    </div>
    <div v-else class="flex items-center justify-center flex-col">
      <h1
        class="text-center md:text-left font-extrabold text-3xl md:text-4xl font-garnett text-primary mb-5"
      >
        Contact Us
      </h1>
      <p class="text-primary text-base md:text-xl mb-12 text-center">This site is still in beta so please email us if you run into any issues.</p>
      <a
        class="bg-lightBlue2 py-3.5 px-16 rounded-sm block text-base font-medium text-white hover:text-grey-200 hover:shadow-lg"
        href="mailto:support@ablestar.co"
        >Contact Us</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  props: ["theme"],
};
</script>
